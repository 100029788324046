import { PageRouter } from '../models/page-router.models';

export const PAGE_MODULE = {
  authentication: 'auth',
  home: 'home',
};

export const PAGE_ROUTER: PageRouter = {
  authentication: {
    login: 'login',
    forgotPassword: 'forgot-password',
    changePassword:'change-password'
  },
  home:"home",
  files: "files",
  reports: "reports",
  file_submission: "file-submission",
  file_history: "file-history",
  bars_payment_reports:"bars-payment-reports",
  contact_us:"contact-us",
  admin_tools:"admin",
  file_submission_success: "file-submission-success",
  reconciliation_reports:"reconciliation-reports",
  coop_imr_enrolled_dealers: "coop-imr-enrolled-dealers"
};

export function getNavLink(pageLink: string) {
  let module = '';
  if (pageLink === PAGE_ROUTER.contact_us) {
    module = '/home';
  } else if (pageLink === PAGE_ROUTER.file_history || pageLink === PAGE_ROUTER.file_submission) {
    module = '/files'
  } else if (pageLink === PAGE_ROUTER.bars_payment_reports || pageLink === PAGE_ROUTER.reconciliation_reports
    || pageLink === PAGE_ROUTER.coop_imr_enrolled_dealers) {
    module = '/reports'
  }
  return module + '/' + pageLink;
}
